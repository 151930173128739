@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'CadillacSansMedium', sans-serif;
$boldFontFamily: 'CadillacSansBold', sans-serif;
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #333333;
$linkColor: #282828;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #282828;
$priceColor: #282828;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #282828;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #252F32;
$colorBtnAlpha: #fff;
$bgBtnBeta: #282828;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #252F32;
$colorBtnOutlineBeta: #252F32;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #8a1529;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #000000;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #282828;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #282828;
$progressBarCloseButtonBG: #282828 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

$gold: #282828;
$cadillacBlue: #282828;
$darkBlue: #252F32;
$darkGrey: #4B4B4B;
$srButton2Color: #282828;

//
// Styles
//

@include breakpoint(mobile){
    .widget-sr{
        &.makes__cadillac{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}

.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__cadillac{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor;
        }
        #button-register.sr-button-1{
            background: $gold;
        }
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(3){
            border-bottom-color: $darkBlue !important;
        }
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(2){
            border-bottom-color: $darkGrey !important;
        }
        .sr-progressBar .TransactionSummaryUnit--single:first-child{
            border-bottom-color: #C8C8C8 !important;
        }
        .TransactionSummary--wrapper--title.is-open+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single{
            border-bottom-color: transparent !important;
        }
        .section-showroom-header__progressBar-stage.selected .progressBar-stage__active-effect{
            background-color: $cadillacBlue;
            fill: $cadillacBlue;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: $gold;
        }
        .vehicle-tile .sr-link{
            color: $darkGrey;
        }
        #calculator-toggle-button.sr-button-1{
            background: $cadillacBlue;
        }
        .btn-step.previous.sr-button-1{
            background: $cadillacBlue;
        }
        .btn-step.next.sr-button-1.is-darker{
            background: $darkBlue;
        }
        .style-specs__lists__see-more.sr-link{
            color: $cadillacBlue;
        }
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon{
            color: $darkGrey;
        }
        .selection-year-bloc__selectButton .sr-button-outline-2.is-hover,
        .selection-year-bloc__selectButton .sr-button-outline-2.is-hover:hover{
            background: #C8C8C8 !important;
            color: #4B4B4B !important;
            border-color: #C8C8C8 !important;
        }
        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before,
        .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before{
            border-color: $gold !important;
        }
        .widget-sr input[type=radio]+label:after{
            left: 6px !important;
        }
        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:after,
        .widget-profile-popupElement form#profileForm [type=checkbox]:not(:checked)+label:after,
        .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before{
            color: $gold;
        }
        .listing-tile .listing-tile-image .car-tagline,
        .listing-used-bodystyle.selected:before{
            color: $gold;
        }
        .listing-used-bodystyle.selected.is-price,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            color: $gold;
            border-color: $gold;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$gold 55%);
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: $gold !important;
        }
        .message-handler.warning{
            background-color: $cadillacBlue;
        }
        .sr-button-2{
            background: transparent !important;
            border: 1px solid $srButton2Color !important;
            color: $srButton2Color !important;
            &:hover{
                background: $srButton2Color !important;
                color: #ffffff !important;
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }

}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__cadillac{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}